import createAction from '../services/create-action.js';
import { fetchCounters } from '../../app/actions/fetch-counters.js';

export const FETCH_CATEGORIES_REQUEST = 'categories/FETCH_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'categories/FETCH_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'categories/FETCH_FAILURE';

export const fetchCategoriesRequest = createAction(FETCH_CATEGORIES_REQUEST);
export const fetchCategoriesSuccess = createAction(
  FETCH_CATEGORIES_SUCCESS,
  (payload) => payload,
  (payload, meta) => meta,
);
export const fetchCategoriesFailure = createAction(FETCH_CATEGORIES_FAILURE);

const fetchCategoriesBase = (path) => {
  return (dispatch, getState, { request }) => {
    dispatch(fetchCategoriesRequest());
    const promise = request(path);

    return promise
      .then(
        (categories) => {
          dispatch(fetchCounters({ categoryIds: categories.map((c) => c._id) }));
          dispatch(fetchCategoriesSuccess(categories));
        },
        () => dispatch(fetchCategoriesFailure()),
      )
      .then(() => promise);
  };
};

export const fetchAllCategories = () => fetchCategoriesBase('/categories/all');
export const fetchSubcategories = (categoryId) =>
  fetchCategoriesBase(`/categories/${categoryId}/subcategories`);
