import { SET_PAGER_STATE, RESET_PAGER_STATE } from '../actions/comments-pagination-actions';

const DEFAULT_STATE = { commentsPaginationConfig: {}, pagerState: { type: 'NONE' } };

export function commentsPagination(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_PAGER_STATE:
      return { ...state, [action.payload.resourceId]: { ...action.payload } };
    case RESET_PAGER_STATE:
      return DEFAULT_STATE;
    default:
      return state;
  }
}
