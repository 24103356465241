import { Ordering } from '@wix/comments-ooi-client/controller';
import { CommentsOrdering } from '../../types';
import { CommentsOrderingMap } from '../../constants/comments-pagination-config';

export const getCommentsPaginationConfig = (
  commentLimit: number,
  commentsOrdering?: CommentsOrdering,
) => {
  return {
    replyShowMoreLimit: 2,
    ordering: CommentsOrderingMap[commentsOrdering!] || Ordering.LATEST_FIRST,
    initialPage: {
      offset: 0,
      commentLimit,
      replyLimit: 0,
    },
    pagination: {
      commentLimit: 5,
      replyLimit: 5,
    },
    maxPagesBeforeDrop: {
      commentPages: 5,
      replyPages: 4,
    },
  };
};
