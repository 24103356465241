import { AppDispatch } from '../reducers/app-dispatch';
import { RootState } from '../types/store-types';
import { WixCommentsApi } from '../types';

export const focusCommentBox = (resourceId: string) => {
  return (
    dispatch: AppDispatch,
    getState: () => RootState,
    { wixCommentsApiRef }: { wixCommentsApiRef: { current: WixCommentsApi | null } },
  ) => {
    return wixCommentsApiRef.current?.affect.tryOpenCommentBox(resourceId, {
      shouldFocus: true,
      shouldScroll: true,
    });
  };
};
