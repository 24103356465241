import { filter } from 'lodash';
import { getLocation, getPrevPath, getQuery } from '../../common/store/location/location-selectors';
import detectRoute, { CATEGORIES_PAGE } from '../services/detect-route';
import { CATEGORY_GUIDELINES, FORUM_GUIDELINES } from '../selectors/guidelines-selectors';
import {
  FeedType,
  getActiveFeedName,
  getFeedType,
  MainPageType,
  getFeedOrigin,
} from '../containers/header-navigation';
import { getIsMainPageEnabled } from '../selectors/app-settings-selectors';
import { getPreviousMatches } from '../../common/router/router-selectors';
import { ROUTE_CATEGORY } from '@wix/communities-forum-client-commons';
import { ReactionCodeValue as ReactionCode } from '@wix/comments-ooi-client/reaction-types';

export const POST_LIMITER_VALUES = {
  'forum-limit-1-new-post': 1,
  'forum-limit-5-new-post': 5,
  'forum-limit-10-new-post': 10,
};

const DUMMY_VISITOR_ID = '00000000-0000-0000-0000-000000000000';

export function getReferrer(state) {
  return detectRoute(getPrevPath(state));
}

export function getCurrentOrigin(state) {
  return detectRoute(getLocation(state));
}

export function getPreviousOrigin(state) {
  const prevMatches = getPreviousMatches(state);
  return detectRoute(prevMatches[1]);
}

export function getImageCount(entity) {
  const content = entity.content;
  return filter(content.entityMap, (entity) => entity.type === 'wix-draft-plugin-image').length;
}

export function getFileCount(entity) {
  const content = entity.content;
  return filter(content.entityMap, (entity) => entity.type === 'wix-draft-plugin-file-upload')
    .length;
}

export function getHashtagCount(entity) {
  const hashtags = entity.hashtags || [];
  return hashtags.length;
}

export function getGifCount(entity) {
  const content = entity.content;
  return filter(content.entityMap, (entity) => entity.type === 'wix-draft-plugin-giphy').length;
}

export function getPollCount(entity) {
  const content = entity.content;
  return filter(content.entityMap, (entity) => entity.type === 'wix-draft-plugin-poll').length;
}

export function getEmojiCount(entity) {
  const content = entity.content;
  return filter(content.entityMap, (entity) => entity.type === 'EMOJI_TYPE').length;
}

export function getMentionCount(entity) {
  const content = entity.content;
  return filter(content.entityMap, (entity) => entity.type === 'mention').length;
}

export function getQuoteCount(entity) {
  const content = entity.content;
  return filter(content.blocks, (entity) => entity.type === 'blockquote').length;
}

export function getVideoCount(entity) {
  const content = entity.content;
  return filter(
    content.entityMap,
    (entity) => entity.type === 'wix-draft-plugin-video' || entity.type === 'VIDEO-EMBED',
  ).length;
}

export function hasCustomVideo(entity) {
  const content = entity.content;
  return (
    filter(
      content.entityMap,
      (entity) =>
        entity.type === 'wix-draft-plugin-video' && entity.data && entity.data.isCustomVideo,
    ).length > 0
  );
}

export function hasBiToken(event) {
  return !!event.biToken;
}

export function isDummyVisitorId(visitorId) {
  return visitorId === DUMMY_VISITOR_ID;
}

export function hasMemberId(event) {
  return !!event.member_id;
}

export const getReportType = (type) => {
  const typeMap = {
    1: 'offensive_content',
    2: 'offensive_media',
    3: 'spam',
  };
  return typeMap[type];
};

export function getOriginFromAppSectionParams(state) {
  const query = getQuery(state) || {};
  let appSectionParams;
  try {
    appSectionParams = JSON.parse(query.appSectionParams);
  } catch (e) {
    appSectionParams = { origin: query.origin };
  }
  return appSectionParams.origin;
}

export const getGuidelinesType = (type) => {
  return {
    [CATEGORY_GUIDELINES]: 'category_guidelines',
    [FORUM_GUIDELINES]: 'forum_guidelines',
  }[type];
};

export const getTabNameByFeedType = (type) => {
  return {
    [FeedType.Categories]: 'categories',
    [FeedType.Posts]: 'all_posts',
    [FeedType.MyPosts]: 'my_posts',
  }[type];
};

export const getCreatePostCTAOrigin = (state) => {
  const route = getCurrentOrigin(state);
  const queryFeedType = getFeedType(state);
  const feedOrigin = feedTypeToTabName(getFeedOrigin(state, route, queryFeedType));

  return feedOrigin ? `live_${feedOrigin}` : 'live_category';
};

export const getBasicCreatePostCTAOrigin = (state, getByRoute) => {
  if (getByRoute) {
    const route = getCurrentOrigin(state);

    return route === CATEGORIES_PAGE ? 'main_page' : 'category';
  } else {
    const prevMatches = getPreviousMatches(state);
    const isPrevCategoriesList = prevMatches[1]?.route === ROUTE_CATEGORY;

    return isPrevCategoriesList ? 'category' : 'main_page';
  }
};

export const getCreatePostOrigin = (state, isEdit) => {
  const isMainPageCategoriesList = getIsMainPageEnabled(state, state);
  const mainPageType = isMainPageCategoriesList ? MainPageType.Categories : MainPageType.Posts;
  const queryFeedType = getActiveFeedName(state, mainPageType);

  const prevMatches = getPreviousMatches(state);
  const isPrevCategoriesList = prevMatches[1]?.route === ROUTE_CATEGORY;

  const feedOrigin = {
    [FeedType.Categories]: 'live_categories_tab',
    [FeedType.Posts]: 'live_all_posts_tab',
    [FeedType.MyPosts]: 'live_my_posts_tab',
  }[queryFeedType];

  return isPrevCategoriesList ? 'live_category' : isEdit ? 'live_edit_post' : feedOrigin;
};

export const feedTypeToTabName = (feedType) => {
  return (
    {
      [FeedType.Categories]: 'categories_tab',
      [FeedType.Posts]: 'all_posts_tab',
      [FeedType.MyPosts]: 'my_posts_tab',
    }[feedType] || feedType
  );
};

export const getPinDurationValue = (duration) => {
  return {
    [24 * 60]: '1day',
    [7 * 24 * 60]: '1week',
    [30 * 24 * 60]: '1month',
    [null]: 'infinitely',
  }[duration];
};

export const ReactionCodeBIMap = {
  [ReactionCode.ANGRY]: 'angry',
  [ReactionCode.LIKE]: 'like',
  [ReactionCode.HAHA]: 'haha',
  [ReactionCode.SAD]: 'sad',
  [ReactionCode.WOW]: 'wow',
  [ReactionCode.CLAP]: 'clap',
  [ReactionCode.THUMBS_UP]: 'thumbs_up',
  [ReactionCode.THINKING]: 'thinking',
  [ReactionCode.LOVE]: 'love',
  [ReactionCode.SMILE]: 'smile',
};
