export const FETCH_FORUM_DATA_REQUEST = 'forumData/FETCH_REQUEST';
export const FETCH_FORUM_DATA_SUCCESS = 'forumData/FETCH_SUCCESS';
export const FETCH_FORUM_DATA_FAILURE = 'forumData/FETCH_FAILURE';

const fetchForumDataRequest = () => ({ type: FETCH_FORUM_DATA_REQUEST });
const fetchForumDataSuccess = (payload) => ({ type: FETCH_FORUM_DATA_SUCCESS, payload });
const fetchForumDataFailure = () => ({ type: FETCH_FORUM_DATA_FAILURE });

export function fetchForumData(promise) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchForumDataRequest());

    promise = promise || request('/forums/this');
    return promise
      .then((forumData) => dispatch(fetchForumDataSuccess(forumData)))
      .catch(() => dispatch(fetchForumDataFailure()))
      .then(() => promise);
  };
}
