import { getLocalStorage } from './local-storage-selectors';
import { get } from 'lodash';
import { getMetaSiteId } from '../../common/store/instance-values/instance-values-selectors';
import { getForumData } from './forum-data-selectors';
import { getAllCategories, getCategory } from '../../common/selectors/categories-selectors';

export const CATEGORY_GUIDELINES = 'category-guidelines';
export const FORUM_GUIDELINES = 'forum-guidelines';

export const getStorageKey = (type, metaSiteId, forumData, category) => {
  if (type === CATEGORY_GUIDELINES) {
    return `${CATEGORY_GUIDELINES}|${metaSiteId}|${category._id}|${category.guidelinesActivityDate}`;
  }

  if (type === FORUM_GUIDELINES) {
    return `${FORUM_GUIDELINES}|${metaSiteId}|${forumData.guidelinesActivityDate}`;
  }
};

const isCategoryGuidelinesHidden = (state, category) => {
  const metaSiteId = getMetaSiteId(state);
  return get(
    getLocalStorage(state),
    getStorageKey(CATEGORY_GUIDELINES, metaSiteId, null, category),
    false,
  );
};

const isForumGuidelinesHidden = (state, forumData) => {
  const metaSiteId = getMetaSiteId(state);
  return get(getLocalStorage(state), getStorageKey(FORUM_GUIDELINES, metaSiteId, forumData), false);
};

const getDisplayedGuidelinesType = (category, forumData) => {
  if (category && category.guidelinesEnabled) {
    return CATEGORY_GUIDELINES;
  }

  if (forumData.guidelinesEnabled) {
    return FORUM_GUIDELINES;
  }
};

export const getDisplayGuidelinesTypeFromStore = (state, categoryId) => {
  const forumData = getForumData(state);
  const category = getCategory(state, categoryId);

  return getDisplayedGuidelinesType(category, forumData);
};

export const areGuidelinesAvailable = (state) => {
  const forumData = getForumData(state);

  return (categoryId) => {
    const category = getCategory(state, categoryId);
    const guidelinesType = getDisplayedGuidelinesType(category, forumData);

    if (guidelinesType === CATEGORY_GUIDELINES) {
      return category.guidelinesEnabled;
    }

    if (guidelinesType === FORUM_GUIDELINES) {
      return forumData.guidelinesEnabled;
    }

    return false;
  };
};

export const areAnyGuidelinesAvailable = (state) => {
  const forumData = getForumData(state);

  return forumData.guidelinesEnabled || getAllCategories(state).some((c) => c.guidelinesEnabled);
};

export const areGuidelinesVisible = (state) => {
  const forumData = getForumData(state);
  const forumGuidelinesHidden = isForumGuidelinesHidden(state, forumData);

  return (categoryId) => {
    const category = getCategory(state, categoryId);
    const guidelinesType = getDisplayedGuidelinesType(category, forumData);

    if (guidelinesType === CATEGORY_GUIDELINES) {
      return !isCategoryGuidelinesHidden(state, category);
    }

    if (guidelinesType === FORUM_GUIDELINES) {
      return forumData.guidelinesEnabled && !forumGuidelinesHidden;
    }

    return false;
  };
};

export const getGuidelinesContent = (state) => {
  const forumData = getForumData(state);

  return (categoryId) => {
    const category = getCategory(state, categoryId);
    const guidelinesType = getDisplayedGuidelinesType(category, forumData);

    if (guidelinesType === CATEGORY_GUIDELINES) {
      return category.guidelinesContent;
    }

    if (guidelinesType === FORUM_GUIDELINES) {
      return forumData.guidelinesContent;
    }
  };
};
